import {configureStore} from "@reduxjs/toolkit";
import {profileReducer} from "./slices/profile";
import {challengesReducer} from "./slices/challenges";
import {challengeReducer} from "./slices/challenge";
import {menuDrawerReducer} from "./slices/menuDrawer";
import {contentOverlayReducer} from "./slices/contentOverlay";
import {feedReducer} from "./slices/feed";
import {overlayReducer} from "./slices/overlay";
import {contentFeedReducer} from "./slices/contentFeed";
import {voteDrawerReducer} from "./slices/voteDrawer";
import {notificationsReducer} from "./slices/notifications";
import {paymentsReducer} from "./slices/payments";
import {contentReducer} from "./slices/content";
import {mysteryReducer} from "./slices/mystery";
import {mysteryEditOverlayReducer} from "./slices/mysteryEditOverlay";
import {configReducer} from "./slices/config";
import {signupReducer} from "./slices/signup";
import {authOverlayReducer} from "./slices/authOverlay";
import {followersReducer} from "./slices/followers";
import {chatReducer} from "./slices/chat";
import {fakeLoaderOverlayReducer} from "@app/store/slices/fakeLoaderOverlay";
import {dollarAnimationReducer} from "@app/store/slices/dollarAnimation";

const makeStore = () => {
  return configureStore({
    reducer: {
      config: configReducer,
      chat: chatReducer,
      overlay: overlayReducer,
      menuDrawer: menuDrawerReducer,
      voteDrawer: voteDrawerReducer,
      authOverlay: authOverlayReducer,
      signup: signupReducer,
      contentOverlay: contentOverlayReducer,
      mysteryEditOverlay: mysteryEditOverlayReducer,
      profile: profileReducer,
      followers: followersReducer,
      feed: feedReducer,
      contentFeed: contentFeedReducer,
      content: contentReducer,
      mystery: mysteryReducer,
      challenges: challengesReducer,
      challenge: challengeReducer,
      notifications: notificationsReducer,
      payments: paymentsReducer,
      fakeLoaderOverlay: fakeLoaderOverlayReducer,
      dollarAnimation: dollarAnimationReducer,
    },
    devTools: process.env.NODE_ENV !== "production",
  });
};

export const store = makeStore();
