import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface User {
  uid: string;
  username: string;
  full_name: string;
  avatar: string;
}

interface Payment {
  uid: string;
  purchase_type: string;
  amount: number;
  amount_rub: number;
  votes_count: number;
  status: string;
  message: string;
}

export interface DollarAnimationData {
  user?: User;
  payment?: Payment;
}

const dollarAnimationInitial: DollarAnimationData = {
  user: undefined,
  payment: undefined,
};

const dollarAnimationSlice = createSlice({
  name: "dollarAnimation",
  initialState: dollarAnimationInitial,
  reducers: {
    setDollarAnimationData: (
      state,
      action: PayloadAction<{user: User; payment: Payment}>,
    ) => {
      const {user, payment} = action.payload;

      state.user = user;
      state.payment = payment;
    },
    clearDollarAnimationData: () => {
      return dollarAnimationInitial;
    },
  },
});

export const {setDollarAnimationData, clearDollarAnimationData} =
  dollarAnimationSlice.actions;

export const dollarAnimationSelector = (state: {
  dollarAnimation: DollarAnimationData | null;
}) => state.dollarAnimation;

export const dollarAnimationReducer = dollarAnimationSlice.reducer;
