import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "../type";
import {
  FormState,
  WithoutAuthFormType,
  WithoutAuthRegPlace,
  WithoutAuthRegPoint,
} from "../interfaces/auth";
import {ActionType} from "@pages/user-landing/v3/type";
import {LocalStorageKey} from "@appTypes/localstorage";

interface IPayment {
  price?: number;
  priceRu?: number;
  tipId?: string;
  mysteryBoxId?: string;
  realGiftId?: string;
  onlineGiftId?: string;
  customTipsId?: string;
  voteId?: string;
}

interface IAuthOverlayStore {
  visible: boolean;
  username?: string;
  displayName?: string;
  avatar?: string;
  userId?: string;
  contentId?: string;
  pathname?: string;
  isWithoutAuth: boolean;
  actionType?: ActionType;
  formState: FormState;
  withoutAuthFormType: WithoutAuthFormType;
  regPoint?: WithoutAuthRegPoint;
  regPlace?: WithoutAuthRegPlace;
  payment?: IPayment;
  isCloseButtonHidden?: boolean;
}

const initialState: IAuthOverlayStore = {
  visible: false,
  isWithoutAuth: false,
  username: "",
  formState: localStorage.getItem(LocalStorageKey.IsAgeConfirmed)
    ? FormState.Auth
    : FormState.AgeConfirmation,
  withoutAuthFormType: WithoutAuthFormType.None,
};

export const authOverlaySlice = createSlice({
  name: "authOverlay",
  initialState,
  reducers: {
    openAuthOverlay: (
      state,
      action: PayloadAction<Partial<IAuthOverlayStore>>,
    ) => {
      state.visible = true;

      if (action.payload?.pathname) {
        localStorage.setItem(
          LocalStorageKey.AfterAnonAuthPage,
          action.payload?.pathname,
        );
      }

      if (action.payload?.isCloseButtonHidden) {
        state.isCloseButtonHidden = action.payload.isCloseButtonHidden;
      }

      if (action.payload?.withoutAuthFormType) {
        state.withoutAuthFormType = action.payload.withoutAuthFormType;
      }

      if (action.payload?.username) {
        state.username = action.payload.username;
      }

      if (action.payload?.displayName) {
        state.displayName = action.payload.displayName;
      }

      if (action.payload?.avatar) {
        state.avatar = action.payload.avatar;
      }

      if (action.payload?.userId) {
        state.userId = action.payload.userId;
      }

      if (action.payload?.contentId) {
        state.contentId = action.payload.contentId;
      }

      if (action.payload?.actionType) {
        state.actionType = action.payload.actionType;
      }

      if (action.payload?.regPoint) {
        state.regPoint = action.payload.regPoint;
      }

      if (action.payload?.regPlace) {
        state.regPlace = action.payload.regPlace;
      }

      if (state.isWithoutAuth) {
        state.formState = FormState.Auth;
      }

      if (action.payload?.isWithoutAuth !== undefined) {
        state.isWithoutAuth = action.payload.isWithoutAuth;
      }
    },
    setAuthOverlayContentId: (state, action: PayloadAction<string>) => {
      state.contentId = action.payload;
    },
    setPayment: (state, action: PayloadAction<IPayment>) => {
      state.payment = action.payload;
    },
    closeAuthOverlay: (state) => {
      state.visible = false;
    },
    changeAuthOverlayFormState: (state, action: PayloadAction<FormState>) => {
      state.formState = action.payload;
    },
    clearAuthOverlay: () => {
      return initialState;
    },
  },
});

export const {
  closeAuthOverlay,
  openAuthOverlay,
  setAuthOverlayContentId,
  setPayment,
  changeAuthOverlayFormState,
  clearAuthOverlay,
} = authOverlaySlice.actions;

export const authOverlaySelector = (state: AppState) => state.authOverlay;

export const authOverlayReducer = authOverlaySlice.reducer;
