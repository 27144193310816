import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChannelSortBase} from "stream-chat";
import {AppState} from "../type";
import {initialProfileItemState} from "@app/store/slices/profile";
import {IUser} from "@app/store/interfaces/user";

interface IChatStore {
  sort: keyof ChannelSortBase;
  fillValue: string;
  isHotContentUnpackAnimationVisible: boolean;
  selectedProfile: IUser;
  isChatNowFlowInitiated: boolean;
  isHotContentFlowInitiated: boolean;
  selectedPayingUserWithoutChat?: string;
}

const initialState: IChatStore = {
  sort: "last_message_at",
  fillValue: "",
  isHotContentUnpackAnimationVisible: false,
  selectedProfile: initialProfileItemState,
  isChatNowFlowInitiated: false,
  isHotContentFlowInitiated: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<keyof ChannelSortBase>) => {
      state.sort = action.payload;
    },
    fillInput: (state, action: PayloadAction<string>) => {
      state.fillValue = action.payload;
    },
    setHotContentUnpackAnimationVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isHotContentUnpackAnimationVisible = action.payload;
    },
    setSelectedProfile: (state, action: PayloadAction<IUser>) => {
      state.selectedProfile = action.payload;
    },
    clearSelectedProfile: (state) => {
      state.selectedProfile = initialProfileItemState;
    },
    initiateChatNowFlow: (state, action: PayloadAction<boolean>) => {
      state.isChatNowFlowInitiated = action.payload;
    },
    initiateHotContentFlow: (state, action: PayloadAction<boolean>) => {
      state.isHotContentFlowInitiated = action.payload;
    },
    setSelectedPayingUserWithoutChat: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selectedPayingUserWithoutChat = action.payload;
    },
  },
});

export const {
  setSort,
  fillInput,
  setHotContentUnpackAnimationVisible,
  setSelectedProfile,
  clearSelectedProfile,
  initiateChatNowFlow,
  initiateHotContentFlow,
  setSelectedPayingUserWithoutChat,
} = chatSlice.actions;

export const chatSelector = (state: AppState) => state.chat;

export const chatReducer = chatSlice.reducer;
