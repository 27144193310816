import {privateApi} from "./api";
import axios from "axios";
import {LocalStorageKey} from "@appTypes/localstorage";

export enum LogType {
  Error = "error",
  Info = "info",
  Warning = "warning",
  Debug = "debug",
}

export type ErrorPriority = "high" | "medium" | "low";

interface ILogOptions {
  type: LogType;
  traceId?: string;
  priority?: ErrorPriority;
  data: any;
}

const GOOGLE_TRANSLATE_API_KEY = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY;
const GOOGLE_TRANSLATE_API_URL =
  "https://translation.googleapis.com/language/translate/v2";

const appService = {
  send: async (options: ILogOptions) => {
    const uri = window.location.href;
    const userId = localStorage.getItem(LocalStorageKey.GuestUserId);
    const sendOptions = {
      ...options,
      data: {
        ...options.data,
        userAgent: navigator?.userAgent || "unknown",
        // @ts-ignore
        userAgentDate: navigator?.userAgentData || "unknown",
        os: navigator?.platform || "unknown",
        osVersion: navigator?.appVersion || "unknown",
        // @ts-ignore
        connectionType: navigator?.connection?.effectiveType || "unknown",
        // @ts-ignore
        connectionRtt: navigator?.connection?.rtt || "unknown",
        // @ts-ignore
        connectionDownlink: navigator?.connection?.downlink || "unknown",
      },
      userId,
      uri,
    };
    if (uri.includes("localhost") || uri.includes("192.168.")) {
      return new Promise((resolve) => {
        //TODO use when needed
        // console.log("sent options", sendOptions);
        resolve(null);
      });
    }
    return await privateApi.post("/tech/logs", sendOptions);
  },
  getConfig: async () => {
    return await privateApi.get("/configs");
  },
  getInformationArticle: async (alias: string) => {
    return await privateApi.get(`/articles/${alias}`);
  },
  getCountryList: async () => {
    return await privateApi.get(`/settings/countries`);
  },
  getLanguageList: async () => {
    return privateApi.get(`/settings/languages`);
  },
  translateText: async (text: string, targetLanguage: string) => {
    return await axios.post(
      `${GOOGLE_TRANSLATE_API_URL}?key=${GOOGLE_TRANSLATE_API_KEY}`,
      {
        q: text,
        target: targetLanguage,
      },
    );
  },
};

export default appService;
