import axios from "axios";
import clearStorageUserData from "../utils/clearStorageUserData";
import Amplitude from "@utils/amplitude";
import {LocalStorageKey} from "@appTypes/localstorage";
import {logError} from "@services/logger";

export const baseURL = process.env.REACT_APP_API_URL;

let guestUserId = localStorage.getItem(LocalStorageKey.GuestUserId);

export const privateApi = axios.create({
  baseURL,
  headers: {
    Accept: "application/json, text/plain",
  },
});

export const publicApi = axios.create({
  baseURL,
});

publicApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    logError(error);

    return Promise.reject(error);
  },
);

publicApi.interceptors.request.use(
  (config) => {
    const deviceId = Amplitude.getDeviceId();

    if (guestUserId) {
      config.headers["User-Id"] = guestUserId;
    }

    if (deviceId) {
      config.headers["Device-ID"] = deviceId;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

privateApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LocalStorageKey.Token);
    const deviceId = Amplitude.getDeviceId();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (guestUserId) {
      config.headers["User-Id"] = guestUserId;
    }

    if (deviceId) {
      config.headers["Device-ID"] = deviceId;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// List to hold the request queue
let refreshAndRetryQueue: any[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const processQueue = (error: any, token = null) => {
  refreshAndRetryQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  refreshAndRetryQueue = [];
};

privateApi.interceptors.response.use(
  (response) => {
    if (response.headers["user-id"] && !guestUserId) {
      guestUserId = response.headers["user-id"];
      guestUserId &&
        localStorage.setItem(LocalStorageKey.GuestUserId, guestUserId);
    }
    if (response.headers["user-country"]) {
      const userCountry = response.headers["user-country"];
      userCountry &&
        localStorage.setItem(LocalStorageKey.UserCountry, userCountry);
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    const isUserAuthError = error?.response?.status === 401;
    const isUserAuthDecline = error?.response?.status === 403;

    const isNeedRelogin = error?.response?.data?.detail === "Need relogin";

    if (isUserAuthDecline && isNeedRelogin) {
      clearStorageUserData();
    }

    if (!isUserAuthError && originalRequest.url !== "/tech/logs") {
      logError(error);
    }

    if (
      isUserAuthError &&
      !originalRequest._retry &&
      originalRequest.url !== "/user/info"
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          refreshAndRetryQueue.push({resolve, reject});
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = window.localStorage.getItem(
        LocalStorageKey.RefreshToken,
      );

      return new Promise(function (resolve, reject) {
        axios
          .post(`${baseURL}/auth/token/refresh`, {refreshToken})
          .then(({data}) => {
            localStorage.setItem(LocalStorageKey.Token, data.token);
            localStorage.setItem(
              LocalStorageKey.RefreshToken,
              data.refreshToken,
            );
            localStorage.setItem(LocalStorageKey.StreamToken, data.streamToken);

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.token;
            originalRequest.headers["Authorization"] = "Bearer " + data.token;

            processQueue(null, data.token);

            resolve(axios(originalRequest));
          })
          .catch((err) => {
            clearStorageUserData();

            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    } else {
      return Promise.reject(error);
    }
  },
);
