import {createSlice} from "@reduxjs/toolkit";
import {AppState} from "@app/store/type";

interface IFakeLoaderOverlayState {
  visible: boolean;
  displayName: string;
  avatar: string;
  lastSeen: string;
  online: string;
  blackOverlayVisible: boolean;
  isLoaderOverlayBlack: boolean;
  hasContentId: boolean;
}

const initialState: IFakeLoaderOverlayState = {
  visible: false,
  displayName: "",
  avatar: "",
  lastSeen: "",
  online: "",
  blackOverlayVisible: false,
  isLoaderOverlayBlack: false,
  hasContentId: false,
};

export const fakeLoaderOverlaySlice = createSlice({
  name: "fakeLoaderOverlay",
  initialState,
  reducers: {
    setFakeLoaderOverlayVisible(state, action) {
      state.visible = action.payload;
    },
    setFakeBlackLoaderOverlayVisible(state, action) {
      state.isLoaderOverlayBlack = action.payload;
    },
    setFakeLoaderOverlayUserData(state, action) {
      state.displayName = action.payload.displayName;
      state.avatar = action.payload.avatar;
      state.lastSeen = action.payload.lastSeen;
      state.online = action.payload.online;
      state.blackOverlayVisible = action.payload.blackOverlayVisible;
      state.hasContentId = action.payload.hasContentId;
    },
  },
});

export const {
  setFakeLoaderOverlayVisible,
  setFakeBlackLoaderOverlayVisible,
  setFakeLoaderOverlayUserData,
} = fakeLoaderOverlaySlice.actions;

export const fakeLoaderOverlaySelector = (state: AppState) =>
  state.fakeLoaderOverlay;

export const fakeLoaderOverlayReducer = fakeLoaderOverlaySlice.reducer;
