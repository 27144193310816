import {useEffect} from "react";
import speedTestWorker from "../speed-test-sw";
import {createWorker} from "@utils/createWorker";
import {useAppDispatch} from "@app/store/hooks";
import {partialUpdateConfig} from "@app/store/slices/config";
import {NetworkSpeedType} from "@app/store/interfaces/config";
import Amplitude from "@utils/amplitude";
import {AnalyticIDs} from "@app/store/interfaces/analytics";

const useSpeedTest = () => {
  const dispatch = useAppDispatch();

  const networkSpeedTypeByDuration = (duration: number) => {
    if (duration < 500) {
      Amplitude.track(AnalyticIDs.InternetSpeedTested, {
        downloadingTime: duration,
        speedCategory: "L",
      });
      return NetworkSpeedType.Fast;
    } else if (duration >= 500 && duration < 1500) {
      Amplitude.track(AnalyticIDs.InternetSpeedTested, {
        downloadingTime: duration,
        speedCategory: "M",
      });
      return NetworkSpeedType.Normal;
    } else {
      Amplitude.track(AnalyticIDs.InternetSpeedTested, {
        downloadingTime: duration,
        speedCategory: "S",
      });
      return NetworkSpeedType.Slow;
    }
  };

  const updateNetwork = (event: MessageEvent) => {
    const {type, duration} = event.data;
    if (type === "SPEED_TEST_RESULT") {
      const networkSpeedType = networkSpeedTypeByDuration(duration);

      dispatch(
        partialUpdateConfig({
          networkType: networkSpeedType,
          networkDuration: duration,
        }),
      );
    }
  };

  useEffect(() => {
    const worker = createWorker(speedTestWorker);

    worker.postMessage({
      type: "START_SPEED_TEST",
    });

    worker.addEventListener("message", updateNetwork);
  }, []);
};

export default useSpeedTest;
