import {useLayoutEffect} from "react";
import {useSearchParams} from "react-router-dom";

const ProfileDataClear = () => {
  const [searchParams] = useSearchParams();
  const clearLocalStorage = () => {
    localStorage.clear();
  };
  const clearSessionStorage = () => {
    sessionStorage.clear();
  };
  const clearCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      // Устанавливаем истекший срок и путь для удаления
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  };

  const clearIndexedDB = () => {
    indexedDB
      .databases()
      .then((databases) => {
        databases.forEach((dbInfo: IDBDatabaseInfo) => {
          if (dbInfo.name) {
            indexedDB.deleteDatabase(dbInfo.name);
            console.log(`Database ${dbInfo.name} deleted`);
          }
        });
      })
      .catch((error) => {
        console.error("Error deleting databases: ", error);
      });
  };

  useLayoutEffect(() => {
    const path = searchParams.get("path");
    clearLocalStorage();
    clearSessionStorage();
    clearCookies();
    clearIndexedDB();
    window.location.assign(path ? `/${path}` : "/");
  }, []);

  return null;
};

export default ProfileDataClear;
