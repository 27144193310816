import {createSlice} from "@reduxjs/toolkit";
import {AppState} from "../type";
import {IUserPayments} from "../interfaces/user";

interface IPaymentsState {
  items: IUserPayments[];
  currentPage: number;
  lastPage: boolean;
  loading: boolean;
  error?: string;
}

const initialState: IPaymentsState = {
  items: [],
  currentPage: 1,
  lastPage: false,
  loading: true,
  error: undefined,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setPaymentsLoading: (state) => {
      state.loading = true;
    },
    setPaymentsLoadingSuccess: (state, action) => {
      const loadPage = state.currentPage !== action.payload.nextPage;
      state.loading = false;

      if (!loadPage) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }

      state.lastPage = action.payload.count < 20;
      state.currentPage = action.payload.nextPage;
    },
    setPaymentsLoadingError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearPayments: () => {
      return initialState;
    },
  },
});

export const {
  setPaymentsLoading,
  setPaymentsLoadingSuccess,
  setPaymentsLoadingError,
  clearPayments,
} = paymentsSlice.actions;

export const paymentsSelector = (state: AppState) => state.payments;

export const paymentsReducer = paymentsSlice.reducer;
