import appService, {ErrorPriority, LogType} from "@services/appService";

export const logError = (error: any, priority?: ErrorPriority) => {
  appService
    .send({
      type: LogType.Error,
      priority,
      data: {
        message: error.response?.stack ?? error.response,
      },
    })
    .catch((err) => {
      console.log("[common]", err);
    });
};
