import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "../type";
import {NetworkSpeedType} from "../interfaces/config";

export interface IConfigState {
  serverTimezone: string;
  freeVoteCountdown: number;
  facebookDisabled: number;
  country?: string;
  version?: string;
  networkType?: NetworkSpeedType;
  networkDuration?: number;
  slowNetworkNotificationViewed?: boolean;
  enableRusPayments?: boolean
}

const initialState: IConfigState = {
  serverTimezone: "UTC+5",
  country: undefined,
  freeVoteCountdown: 30,
  facebookDisabled: 0,
  version: undefined,
  networkType: NetworkSpeedType.Normal,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    partialUpdateConfig: (
      state,
      action: PayloadAction<Partial<IConfigState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    clearConfig: () => {
      return initialState;
    },
  },
});

export const {partialUpdateConfig, clearConfig} = configSlice.actions;

export const configSelector = (state: AppState) => state.config;

export const configReducer = configSlice.reducer;
