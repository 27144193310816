export enum SearchParams {
  PaymentId = "paymentId",
  PaymentStatus = "paymentStatus",
  PaymentType = "paymentType",
  PaymentObjectUid = "paymentObjectUid",
  Type = "type",
  ActiveTab = "activeTab",
  Channel = "channel",
  Country = "country",
  Source = "source",
  UnsubscribeStatus = "unsubscribeStatus",
  ConfirmationSuccess = "confirmationSuccess",
  EmailRegistration = "email-registration",
  EmailLogin = "email-login",
  Email = "email",
  Sign = "sign",
  Error = "error",
  Hash = "hash",
  AuthType = "auth_type",
  ChatId = "chat_id",
  UserUid = "u_uid",
  ContentUid = "c_uid",
  ChallengeUid = "ch_uid",
  AnonUserUid = "a_uid",
  AnonRegPoint = "reg_point",
  PersonalUid = "p_uid",
}
